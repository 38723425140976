<template>
	<v-layout pt-3 row wrap>
		<v-flex xs12>
			<v-badge color="transparent">
				<template v-slot:badge>
					<div class="badge-slot">
						<slot name="badge"></slot>
					</div>
				</template>
				<div v-if="versionNumber" class="body-1 font-weight-bold">V{{ versionNumber }} - {{ $translateDateTime(version.created_at, 'verylong') }}</div>
			</v-badge>
				<div v-if="author" :class="`caption grey--text text--${darkenOrLighten}-2`">{{ author.username }}</div>
				<ul v-for="applicationDocumentVersion in version.applicationDocumentVersions" :key="applicationDocumentVersion.id" class="application-document-version">
					<li>
						<div class="caption">{{ applicationDocumentVersion.app.title }}</div>
						<div v-if="applicationDocumentVersion.status === 'error'" class="body-1">
							{{ $t('document.not_sent.message') }} ({{ getExceptionMessage(applicationDocumentVersion) }})
						</div>
						<div v-if="applicationDocumentVersion.status === 'in_progress'" class="body-1">
							{{ $t('document.in_progress') }}
						</div>
						<div v-if="applicationDocumentVersion.status === 'success'" class="body-1">
							{{ $t('document.sent') }}
						</div>
					</li>
				</ul>
			<WModule name="workflow">
				<WorkflowStatusList :version="version"/>
			</WModule>
		</v-flex>
	</v-layout>
</template>
<script>
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
import GlobalParameterService from '@/services/GlobalParameter/GlobalParameterService'
import { mapState } from "vuex";

export default {
	name: 'HistoricVersion',
	components: {
		WorkflowStatusList: () => ({
			component: import('@/components/Workflows/WorkflowStatusList')
		})
	},
	mixins: [DocumentVersionsModuleGuard],
	props: {
		version: {
			type: Object,
			required: false,
			default: null
		},
		versionNumber: {
			type: Number,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			mailAttachmentMaxSize: null
		}
	},
	computed: {
		...mapState({
			isDarkModeEnabled: state => state.user.darkMode
		}),
		author: function () {
			return this.version?.author
		},
		darkenOrLighten() {
			return this.isDarkModeEnabled ? 'lighten' : 'darken'
		}
	},
	watch: {
		version: {
			deep: true,
			handler () {}
		}
	},
	mounted: function () {
		this.loadDocumentVersionAuthor()
		this.loadMailAttachmentMaxSize()
	},
	methods: {
		loadDocumentVersionAuthor: function () {
			if (this.version && !this.version.hasOwnProperty('author') && this.version.user_id) {
				this.service.getDocumentVersionAuthor(this.vendorId, this.version.document_id, this.version)
			}
		},
		loadMailAttachmentMaxSize: function () {
			GlobalParameterService.getGlobalParameter('mailAttachmentMaxSize').then(max => {
				this.mailAttachmentMaxSize = max.value
			})
		},
		getExceptionMessage: function (applicationDocumentVersion) {
			let message = JSON.parse(applicationDocumentVersion.exception).message
			let exceptionMessage = this.$t('document.not_sent.unknown_error')
			switch (message) {
				case 'The given parameters are invalid for this connection.':
					exceptionMessage = this.$t('document.not_sent.invalid_parameters')
					break
				case 'This third party is temporarily unavailable for sending documents':
					exceptionMessage = this.$t('document.not_sent.third_party_unavailable')
					break
				case 'This type of file is not supported':
					exceptionMessage = this.$t('document.not_sent.file_type_not_supported')
					break
				case 'File size limit exceeded':
					exceptionMessage = this.$t('document.not_sent.file_size_limit_exceeded', {
						size: applicationDocumentVersion.app.mail_attachement_max_size
							? applicationDocumentVersion.app.mail_attachement_max_size
							: this.mailAttachmentMaxSize
					})
					break
				case 'This document already exists on the third-party application.':
					exceptionMessage = this.$t('document.not_sent.document_already_exists')
					break
				case 'File is empty':
					exceptionMessage = this.$t('document.not_sent.file_is_empty')
					break
				case 'This file already exists, and is waiting user input for further processing':
					exceptionMessage = this.$t('document.not_sent.ami_compta.already_exists')
					break
				default:
					exceptionMessage = this.$t('document.not_sent.unknown_error')
			}
			return exceptionMessage
		}
	}
}
</script>

<style scoped>
.badge-slot {
	width: 24px;
	height: 24px;
}
.application-document-version {
	list-style-type: none;
	font-style: italic;
}
</style>
